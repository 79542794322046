.todays--deal{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 20px;
    background-color: white;
    z-index: 1;
    position: relative;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    box-shadow: 0px 2.98256px 7.4564px rgba(0, 0, 0, 0.1);
}

.deal--img{
    width: 180px;
    height: 180px;
}

.deal--item{
    display: flex;
    flex-direction: column;
    margin: 10px;
    padding: 20px;
    margin-top: 20px;
    gap: 5px;
    justify-content: center;
    background-color: white;
    z-index: 1;
    position: relative;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0px 2.98256px 7.4564px rgba(0, 0, 0, 0.1);
}

.deal--container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    overflow: auto;
    white-space: nowrap;
}

.deal--container::-webkit-scrollbar {
    display: none;
}

.deal--item--offer{
    display: flex;
    font-size: 12px;
    margin-top: 10px;
    font-family: sans-serif;
    align-items: center;
    gap: 5px;
    color: #cc0c39;
}

.offer-percent{
    background-color: #cc0c39;
    padding: 5px;
    color: white;
}

.deal--item--title{
    font-size: 14px;
    font-family: sans-serif;
    margin-top: 10px;
}

.todays--header{
    display: flex;
    gap: 20px;
    align-items: center;
}

.todays--header a{
    text-decoration: none;

}