.checkoutProduct{
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    justify-content: center;
    gap: 2%;
    padding-bottom: 20px;
    border-bottom: 1px solid lightgray;
}

.checkoutProduct--info{
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.checkoutProduct--price--title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 19px;
    font-weight: 700;
    font-family: sans-serif;
}

.checkoutProduct--title{
    padding-right: 10%;
    color: #0f1118;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.checkoutProduct--price{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
}

.checkoutProduct--fba--img{
    width: 70px;
    height: 18px;
    margin-top: 2px;
}

.checkoutProduct--instock{
    color: #007600;
    font-size: 12px;
    font-weight: 700;
}

.checkoutProduct--free--ship{
    color: #5b615e;
    font-size: 12px;
    font-weight: 700;
    margin-top: 10px;

}

.checkoutProduct--image{
    width: 180px;
    height: 180px;
}

.checkoutProduct--title{
    font-size: 17px;
    font-weight: 800;
}

.checkout--gift{
    display: flex;
    align-items: center;
    gap: 5px;
    font-family: sans-serif;
    font-size: 12px;
    margin-top: 5px;
}

.checkout--learn--more{
    color: #007185;
    font-weight: 700;
    cursor: pointer;
}

.checkout--size{
    font-size: 12px;
    font-weight: 700;
    margin-top: 10px;
    color: #565b5a;
}

.checkoutproduct--options{
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
    flex-wrap: wrap;
    color: #007185;
    font-size: 15px;
}

.checkoutproduct--options h5{
    cursor: pointer;
}

.checkoutProduct--quantity{
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
    background-color: #e2e9e9;
    padding: 5px 10px 5px 10px;
    border-radius: 8px;
    border: 1px #c1c1c1 solid;
    font-size: 13px;
    font-family: sans-serif;
    font-weight: 700;
    color: #5f6967;
    box-shadow: 0px 2.98256px 7.4564px rgba(0, 0, 0, 0.1);
}

.checkout--size b{
    color: #000000;
}

.separator--vLine{
    border-left: 1px solid lightgray;
    padding-left: 10px;
    margin-left: 10px;
}

.checkoutProduct--rating{
    display: flex;
    color: #f0c14b;
    font-size: 35px;
}

input[type="checkbox"]:checked {
    background-color: rgb(255, 255, 255);
}