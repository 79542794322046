.products--grid{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    margin-bottom: 20px;
    padding: 20px;
    padding-bottom: 40px;
    width: 21%;
    background-color: white;
    z-index: 1;
    position: relative;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    box-shadow: 0px 2.98256px 7.4564px rgba(0, 0, 0, 0.1);
}

.category--title{
    font-size: 20px;
    font-family: sans-serif;
    margin-bottom: 15px;
}

.sub--product{
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    font-family: sans-serif;
    cursor: pointer;
}

.product--img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.product--title{
    font-size: 13px;
}

.rows{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    height: 100%;
}

.row1{
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

.see--more{
    font-size: 12px;
    color: #007185;
    cursor: pointer;
    text-decoration: none;
    position: absolute;
    bottom: 0;
    margin-bottom: 15px;
}