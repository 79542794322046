.login{
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
}

.login--logo{
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100px;
    object-fit: contain;
    margin-right: auto;
    margin-left: auto;
}

.login--container{
    width: 300px;
    background-color: white;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-right: auto;
    margin-left: auto;
    border: 1px solid lightgray;
}

.login--container h1{
    font-weight: 500;
    margin-bottom: 20px;
}

.login--container form h5{
    margin-bottom: 5px;
}

.login--container form input{
    height: 30px;
    background-color: white;
    width: 98%;
    border-radius: 3px;
    border: 1px solid lightgray;
    margin-bottom: 10px;
}

.login--container p{
    margin-top: 15px;
    font-size: 12px;
}

.login--signInButton{
    background-color: #f0c14b;
    border: 2px solid;
    width: 100%;
    height: 30px;
    cursor: pointer;
    color: #111;
    border-color: #eed390;
    margin-top: 10px;
}

.login--registerButton{
    border-radius: 2px;
    width: 100%;
    height: 30px;
    cursor: pointer;
    border: 1px solid;
    margin-top: 10px;
    border-color: darkgray;
}
