.footer{
    width: 100%;
    margin: 0;
}

.footer--container{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.back--to--top{
    width: 100%;
    height: 50px;
    background-color: #37475a;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 12px;
    font-family: sans-serif;
    cursor: pointer;
}

.footer--main--content{
    background-color: #232f3e;
    display: flex;
    justify-content: center;
    gap: 7%;
    padding: 3%;
    font-size: 13px;
}

.footer-column{
    display: flex;
    flex-direction: column;
    color: white;
    gap: 10px;
}

.footer-column ul{
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 13px;
}

.underline{
    width: 100%;
    height: 1px;
    background-color: rgb(85, 85, 85);
}

.footer--bottom{
    background-color: #232f3e;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: white;
    font-size: 12px;
    font-family: sans-serif;
    margin: 0%;
    padding: 2%;
    gap: 15px;
}

.footer--bottom--left img{
    width: 80px;
    align-items: center;
}

.footer--bottom--center{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    cursor: pointer;
    flex-wrap: wrap;
    margin-left: 20%;
    margin-right: 20%;
    gap: 15px;
    font-family: sans-serif;
}

.footer a{
    color: #c5c8c9;
    text-decoration: none;
    font-family: sans-serif;
}

/* .padd{
    margin-left: -20px;
    margin-top: 5px;
} */

.footer--bottom--bottom{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: white;
    cursor: pointer;
    padding: 2%;
    background-color: #131a22;
    gap: 40px;
}

.footer--bottom--bottom--left{
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 4%;
}

.footer--bottom-bottom-column{
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.footer--bottom-bottom-row{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.footer--bottom--bottom--right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.privacy--notice{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.footer--bottom--bottom--right h4{
    font-size: 12px;
    font-family: sans-serif;
    color: #c5c8c9;
}