.subtotal{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    height: 100px;
    padding: 20px;
    background-color: white;
    border: 1px solid #dddddd;
    border-radius: 3px;
}

.subtotal--gift{
    display: flex;
    align-items: center;
    gap: 5px;
}

.subtotal--button{
    background-color: #ffd814;
    border: 1px solid;
    border-radius: 2px;
    width: 100%;
    height: 30px;
    cursor: pointer;
    color: #111;
    border-radius: 7px;
    border-color: #e9d8ac #ddcca3 #d3c8a9;
}