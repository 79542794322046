.product{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    margin-bottom: 20px;
    padding: 20px;
    width: 21%;
    background-color: white;
    z-index: 1;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0px 2.98256px 7.4564px rgba(0, 0, 0, 0.1);
}

.product--info{
    height: 100px;
    margin-bottom: 15px;
}

.product img{
    width: 100%;
    max-height: 200px;
    object-fit: contain;
    margin-bottom: 15px;
}

.product:hover{
    transform: scale(1.05);
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.2);
}

.product--title{
    font-size: 16px;
    font-family: sans-serif;
}

.product--rating{
    display: flex;
}

.star{
    font-size: 35px;
    color: #cd9042;
}

.product--price{
    font-size: 16px;
    font-family: sans-serif;
}

.price--rating{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
}

.add--button{
    background-color: #f0c14b;
    border: 1px solid;
    margin-top: 10px;
    border-color: #a88734 #9c7e31 #846a29;
    color: #111;
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    padding: 5px;
    text-align: center;
    width: 100px;
    border-radius: 5px;
}